// MIXINS FONT
// @mixin roboto-thin() {
//   font-family: 'Roboto', sans-serif;
//   font-weight: 100;
// }
// @mixin roboto-thin() {
//   font-family: 'Roboto', sans-serif;
//   font-weight: 100;
// }
// @mixin gotham-thin() {
//   font-family: 'Gotham', sans-serif;
//   font-weight: 100;
// }
// @mixin gotham-light() {
//   font-family: 'Gotham', sans-serif;
//   font-weight: 300;
// }
// @mixin gotham-bold() {
//   font-family: 'Gotham', sans-serif;
//   font-weight: bold;
// }
// @mixin gotham-book() {
//   font-family: 'Gotham book', sans-serif;
//   font-weight: normal;
// }
// Pour les corps de textes
@mixin gotham-ss-light() {
  font-family:'Gotham SSm A', 'Gotham SSm B', sans-serif;
  font-style: normal;
  font-weight: 300;
}
// Pour les titres
@mixin gotham-thin() {
  font-family: "Gotham A", "Gotham B", sans-serif;
font-style: normal;
font-weight: 100;
}
