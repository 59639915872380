
@font-face {
    font-family: 'Gotham';
    src: url('../fonts/Gotham-Thin.eot');
    src: url('../fonts/Gotham-Thin.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Gotham-Thin.woff') format('woff'),
        url('../fonts/Gotham-Thin.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'Gotham';
    src: url('../fonts/Gotham-Light.eot');
    src: url('../fonts/Gotham-Light.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Gotham-Light.woff') format('woff'),
        url('../fonts/Gotham-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Gotham';
    src: url('../fonts/Gotham-Bold.eot');
    src: url('../fonts/Gotham-Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Gotham-Bold.woff') format('woff'),
        url('../fonts/Gotham-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Gotham';
    src: url('../fonts/Gotham-Medium.eot');
    src: url('../fonts/Gotham-Medium.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Gotham-Medium.woff') format('woff'),
        url('../fonts/Gotham-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Gotham book';
    src: url('../fonts/Gotham-Book.eot');
    src: url('../fonts/Gotham-Book.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Gotham-Book.woff') format('woff'),
        url('../fonts/Gotham-Book.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Gotham';
    src: url('../fonts/Gotham-XLight.eot');
    src: url('../fonts/Gotham-XLight.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Gotham-XLight.woff') format('woff'),
        url('../fonts/Gotham-XLight.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Gotham';
    src: url('../fonts/Gotham-Ultra.eot');
    src: url('../fonts/Gotham-Ultra.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Gotham-Ultra.woff') format('woff'),
        url('../fonts/Gotham-Ultra.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}
