/*HEADER Animation*/
.up > img {
  -moz-transform:translateY(150px);
  -webkit-transform:translateY(150px);
  -o-transform:translateY(150px);
  -ms-transform:translateY(150px);
  transform:translateY(150px);
  transition:all 1s ease-in-out;
}


/* FANTASY */
.fantasy .up .ill2 > img:first-of-type {
  -moz-transform:translateY(150px);
  -webkit-transform:translateY(150px);
  -o-transform:translateY(150px);
  -ms-transform:translateY(150px);
  transform:translateY(150px);
  transition:all 1s ease-in-out;
  transition-delay: 0.2s;
  opacity: 0;
}

.fantasy .up .ill2 > img:last-of-type {
  -moz-transform:translateY(150px);
  -webkit-transform:translateY(150px);
  -o-transform:translateY(150px);
  -ms-transform:translateY(150px);
  transform:translateY(150px);
  transition:all 1s ease-in-out;
  transition-delay: 0.4s;
  opacity: 0;
}


.down > img {
  -moz-transform:translateY(-150px);
  -webkit-transform:translateY(-150px);
  -o-transform:translateY(-150px);
  -ms-transform:translateY(-150px);
  transform:translateY(-150px);
  transition:all 1s ease-in-out;
}
.left > img {
  -moz-transform:translateX(150px);
  -webkit-transform:translateX(150px);
  -o-transform:translateX(150px);
  -ms-transform:translateX(150px);
  transform:translateX(150px);
  transition:all 1s ease-in-out;
}

.fantasy .left.ill .ill2 {
  -moz-transform:translateX(105px);
  -webkit-transform:translateX(105px);
  -o-transform:translateX(105px);
  -ms-transform:translateX(105px);
  transform:translateX(105px);
  transition:all 1s ease-in-out;
  transition-delay: 0.3s;
}

.fantasy  .left.ill .ill2 img{
  -moz-transform:translateX(0px);
  -webkit-transform:translateX(0px);
  -o-transform:translateX(0px);
  -ms-transform:translateX(0px);
  transform:translateX(0px);
  transition:all 1.3s ease-in-out;
  transition-delay: 0.3s;
  opacity: 0;
}

img.left{
  -moz-transform:translateX(150px);
  -webkit-transform:translateX(150px);
  -o-transform:translateX(150px);
  -ms-transform:translateX(150px);
  transform:translateX(150px);
  transition:all 1s ease-in-out;
}

.right > img {
  -moz-transform:translateX(-150px);
  -webkit-transform:translateX(-150px);
  -o-transform:translateX(-150px);
  -ms-transform:translateX(-150px);
  transform:translateX(-150px);
  transition:all 1s ease-in-out;
}

.apparait .up > img,
.apparait .down > img,
.fantasy .apparait .up .ill2 > img:first-of-type,
.fantasy .apparait .up .ill2 > img:last-of-type {
  -moz-transform:translateY(0);
  -webkit-transform:translateY(0);
  -o-transform:translateY(0);
  -ms-transform:translateY(0);
  transform:translateY(0);
  opacity: 1;
}
.apparait .left > img,
.apparait .right > img,
.apparait img.left, .fantasy .apparait .left.ill  .ill2 {
  -moz-transform:translateX(0);
  -webkit-transform:translateX(0);
  -o-transform:translateX(0);
  -ms-transform:translateX(0);
  transform:translateX(0);
  opacity: 1;
}

.fantasy .apparait .left.ill .ill2 img{
  opacity: 1;
}

/*.projet .slide1 .bg-slide1 {
    animation: home-anim_motto 0s ease 0.5s forwards;
    -webkit-animation: home-anim_motto 0s ease 0.5s forwards;
}*/

.projet .container h1 {
    animation: home-anim_text 1.75s ease forwards;
    -webkit-animation: home-anim_text 1.75s ease forwards;
}
.projet .container .motto {
    animation: home-anim_motto 1.5s ease 0.25s forwards;
    -webkit-animation: home-anim_motto 1.5s ease 0.25s forwards;
}
.projet .chapo {
    animation: chapo-a 1.75s ease forwards;
    -webkit-animation: chapo-a 1.75s ease forwards;
}

.esperluette-stroke {
  stroke-dasharray: 400;
  stroke-dashoffset: 400;
  animation: dash 2s cubic-bezier(1, -0.09, 0.48, 0.76) 0.5s forwards 1;
}
.esperluette-fill {
  /*opacity:0;*/
  animation: fillopacity 0.3s linear 2.8s forwards 1;
}

div#logo:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    background: #ffffff;
    height: 100%;
    /*animation: Down 2s cubic-bezier(0.9,-0.03, 0.59, 1.03) 0.5s forwards 1;*/
}

.ct-scrolldown {
    animation: FadeIn 0.4s ease 4s forwards 1;
}
.flecheScrolling {
    animation: animationFleche 0.8s linear 4.3s forwards infinite;
}

.esperluette-shadow {
    position: absolute;
    top: 0px;
    left: 0;
    width: 100%;
    height: 100%;
    opacity:0;
    z-index:4;
    animation: FadeIn 0.4s cubic-bezier(0.9,-0.03, 0.59, 1.03) 2.5s forwards 1;
}

.refferer .esperluette-shadow {
    animation-delay:0s;
}

.logo-top, .logo-bottom {
    position:relative;
    z-index:2;
}
.logo-bottom {
    z-index:3;
    /* margin-top: -126px; */
    width: 100%;
    position: absolute;
    height: 100%;
    left: 0;
    top: 0;
}
.logo-top img {
    position:relative;
    z-index:1;
}

.refferer .esperluette-draw {
    visibility:hidden;
}

.esperluette-draw .logo-top:after {
    content: '';
    position: absolute;
    z-index:3;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: #ffffff;
    /*transition: all 1.5s ease 1.6s;*/
    animation: Up 1.5s cubic-bezier(0.9,-0.03, 0.59, 1.03) 1s forwards 1;
    /* top: -100%; */
}

.esperluette-draw .logo-bottom:after {
    content: '';
    position: absolute;
    z-index:3;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: #ffffff;
    transform-origin: 0 0;
    /*transition: all 1.5s ease;*/
    animation: apparitionLogoBas 1.5s cubic-bezier(0.9,-0.03, 0.59, 1.03) 0.5s forwards 1;
    /* left: -100%; */
    /* left: -100%; */
    /* transform: rotate(-90deg); */
}

/*.show .logo-top:after {
    top: -100%;
}

.show .logo-bottom:after {
    left: -100%;
    transform: rotate(-90deg);
}*/

@keyframes FadeIn {
  from {
    opacity:0;
  }
  to {
    opacity:1;
  }
}

@keyframes apparitionLogoBas {
  from {
    left:0;
    transform:rotate(0deg);
  }
  to {
    left: -100%;
    transform: rotate(45deg);
  }
}

@keyframes Up {
  from {
    top:0%;
  }
  to {
    top:-100%;
  }
}

@keyframes Down {
  from {
    top:0%;
  }
  to {
    top:100%;
  }
}

@keyframes animationFleche {
  0% {
    top:0;
  }
  50% {
    top:-15px;
  }
  100% {
    top:0;
  }
}

@-webkit-keyframes home-anim_text {
    0% {
        opacity: 0;
        -webkit-transform: translateY(-100%);
        -moz-transform: translateY(-100%);
        -ms-transform: translateY(-100%);
        -o-transform: translateY(-100%);
        transform: translateY(-100%);
    }
    /*40% {
        -webkit-transform: translateY(-20%);
        -moz-transform: translateY(-20%);
        -ms-transform: translateY(-20%);
        -o-transform: translateY(-20%);
        transform: translateY(-20%);
    }*/
    100% {
        opacity: 1;
        -webkit-transform: translateY(0%);
        -moz-transform: translateY(0%);
        -ms-transform: translateY(0%);
        -o-transform: translateY(0%);
        transform: translateY(0%);
    }
}
@keyframes home-anim_text {
    0% {
        opacity: 0;
        -webkit-transform: translateY(-100%);
        -moz-transform: translateY(-100%);
        -ms-transform: translateY(-100%);
        -o-transform: translateY(-100%);
        transform: translateY(-100%);
    }
    /*40% {
        -webkit-transform: translateY(-20%);
        -moz-transform: translateY(-20%);
        -ms-transform: translateY(-20%);
        -o-transform: translateY(-20%);
        transform: translateY(-20%);
    }*/
    100% {
        opacity: 1;
        -webkit-transform: translateY(0%);
        -moz-transform: translateY(0%);
        -ms-transform: translateY(0%);
        -o-transform: translateY(0%);
        transform: translateY(0%);
    }
}

@-webkit-keyframes home-anim_motto {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
@keyframes home-anim_motto {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}


@-webkit-keyframes chapo-a {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}
@keyframes chapo-a {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}


/* ==========================================================================
APPARITIONS PAGES PROJET
========================================================================== */
.pstep .fullpage:before {
  animation: PreviousStep 1s cubic-bezier(0.2, 1, 0.3, 1) forwards 1;
}
.nstep .fullpage:after {
  animation: NextStep 1s cubic-bezier(0.2, 1, 0.3, 1) forwards 1;
}



@keyframes PreviousStep {
  from {
    left:0;
  }
  to {
    left:100%;
  }
}
@keyframes NextStep {
  from {
    right:0;
  }
  to {
    right:100%;
  }
}