/* ==========================================================================
Pages Projets
========================================================================== */

.motto {
  color: #FFF;
  opacity:0;
  margin: 10px auto 80px auto;
  width: 85%;
  @include gotham-ss-light;
  // text-shadow: 1px 1px 1px #333;
  text-align: center;
  font-size:16px;
}

// REGLE: on demarre de projet, chaque singularisation va dans le nom de projet (verdun, atelier)
.projet {
  h1 {
    margin-bottom: 0;
    padding-bottom:0;
    @include gotham-thin;
    font-size: 90px;
    margin-top: 75px;
  }
  .slide1 {
    .chapo {
      font-size:19px;
    }
  }
  // overflow-x: hidden;
  .slide2,.slide3, .slide4, .slide5 {
    position: relative;
    max-width:1950px;
  }
  .chapter{
    p {
      margin-top: 20px;
      font-size: 15px;
    }
  }
  .ch-r {
    max-width: 490px;
  }
  .ch-l {
    max-width: 490px;
  }
  .ill {
    position:relative;
    img {
      position:absolute;
      left:0;
      top:0;
      max-width:none;
    }
  }
  .slide1{
    min-height:100vh;
    overflow:hidden;
    text-align: left;
    .device {
      max-width: 100%;
      height: auto;
      margin: auto;
      z-index: 10;
      text-align:center;
    }
    .container {
      position:relative;
      z-index:2;
    }
    .bg-slide-blur {
      position:absolute;
      top:0;
      bottom:0;
      right:0;
      left:0;
      -webkit-background-size: cover; /* pour anciens Chrome et Safari */
      // background-size: cover; /* version standardisée */
      background-size: cover;
      // -webkit-gradient(linear, 0 0, 0 100%, from(#FFF), to(#000));
      background-position: center;
      opacity:0;
      img {
        width: 100%;
        height: auto;
        min-height: 100%;
      }
    }
    .bg-slide1 {
      position:absolute;
      top:0;
      bottom:0;
      right:0;
      left:0;
      -webkit-background-size: cover; /* pour anciens Chrome et Safari */
      // background-size: cover; /* version standardisée */
      background-size: cover;
      // -webkit-gradient(linear, 0 0, 0 100%, from(#FFF), to(#000));
      background-position: center;
      opacity:0;
    }
    &:before {
      content: '';
      position: absolute;
      height: 300px;
      right: 0;
      bottom: 0;
      left: 0;
      /* FF3.6-15 */
      background: -webkit-linear-gradient(rgba(255,255,255,0) 0%, rgba(255,255,255,0.7) 50%, rgba(255,255,255,0.9) 75%, rgba(255,255,255,1) 100%);
      background: -o-linear-gradient(rgba(255,255,255,0) 0%, rgba(255,255,255,0.7) 50%, rgba(255,255,255,0.9) 70%, rgba(255,255,255,1) 100%);
      background: linear-gradient(rgba(255,255,255,0) 0,rgba(255,255,255,.7) 50%,rgba(255,255,255,.9) 71%,#fff 100%); /* Chrome10-25,Safari5.1-6 */ /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=0 ); /* IE6-9 */
      opacity: 0;
      z-index:1;
      transition:opacity 0.3s ease;
    }
    &.view .bg-slide1 {
      opacity:1;
    }
    &.view .bg-slide-blur {
      opacity:1;
    }
    &.view:before {
      opacity:1;
    }
  }
  /*.slide2 {
    .ill img {
      visibility: hidden;
    }
  }*/
  .slide4 {
    .ill1 img {
      /*
      Positions finales
      left: -350px;
      top: 200px;
      Init animations
      */
    }
  }
}
.footer-contact {
  border-top: 1px solid #e6e6e6;

  .contact-container {
    margin: 20px auto;
    display: flex;
    width: 1000px;
    .contact-sub {
      width: 50%;
      display: flex;
    }
  }
  h4, p {
      margin: 0;
  }
  h4 {
    margin-top: 0px;
    font-size: 20px;
    font-weight: 200;
    padding: 0;
    padding-right: 10px;
  }
  p {
    padding: 5px 10px;
    font-size: 13px;
    color: #787878;
    a {
      color: #787878;
    }
  }
}
.voirSite {
  margin: 20px auto;
  justify-content: center;
  margin-bottom: 130px;
  a {
    margin: auto;
  }
}

body.projet {
  .nav {
    opacity:0;
    &.view {
      opacity:1;
    }
  }
}


/* ==========================================================================
BNP ATELIER
========================================================================== */

.atelier {
  .slide1{
    // Avec degrade
    //background-color:$atelier;
    /*.bg-slide-blur {
      background-image:url("../img/atelier/atelier_bk-blur.jpg");
    }*/
    .bg-slide1 {
      background-image:url("../img/atelier/atelier_bk.jpg");
    }
  }
  .slide2 {
    background: url(../img/atelier/atelier_lg_bk.gif) 45% 100% no-repeat;
    .ill img {
      //left:-15px;
      left:-580px;
      top:-30px;
      //max-width:100%;
    }
    .chapter {
      padding-top:730px;
      padding-bottom:300px;
    }
  }
  .slide3 {
    .chapter {
      padding-top:10%;
    }
    .ill {
      text-align:center;
      img {
        position: relative;
        max-width:100%;
      }
    }
  }
  .slide4 {
    .chapter {
      padding-top:320px;
      padding-bottom:283px;
    }
    .ill img {
      left: -600px;
      max-width: 110%;
      z-index:1;
    }
  }

}

/* ==========================================================================
BNP
========================================================================== */
.bnp {
  .slide1{
    // Avec degrade
    //background-color:$bnp;
    /*.bg-slide-blur {
      background-image:url("../img/bnp/bnp_bk-blur.jpg");
    }*/
    .bg-slide1 {
      background-image:url("../img/bnp/bnp_bk.jpg");
    }
  }
  .slide2 {
    .ill {
      text-align:center;
      img {
        position:relative;
        margin-top:-60px;
        margin-left:-40px;
      }
    }
    .chapter {
      margin-top: 75px;
      margin-bottom:0px;
    }
  }
  .slide3 {
    .chapter {
      padding-top:700px;
      padding-bottom:250px;
    }
    .ill1 img {
      //left:-15px;
      left: -650px;
    }
  }
  .slide4 {
    background: url(../img/bnp/bnp_lg_bk.gif)  35% 100%  no-repeat;
    .chapter {
      padding-top:36%;
    }
    .ill img{
      left:0px;
      position:relative;
      max-width:110%;
    }
  }
}

/* ==========================================================================
DIMA
========================================================================== */
.dima {
  .slide1{
    // Avec degrade
    //background-color:$dima;
    /*.bg-slide-blur {
      background-image:url("../img/dima/dima_bk_grad-blur.jpg");
    }*/
    .bg-slide1 {
      background-image:url("../img/dima/dima_bk_grad.jpg");
    }
  }
  .slide2 {
    background: url(../img/dima/dima_lg_bk.gif) 45% 90% no-repeat;
    .ill img {
      top:-80px;
      //left:-15px;
      left: -550px;
    }
    .chapter {
      padding-top:450px;
      padding-bottom:320px;
    }
  }
  .slide3 {
    .chapter {
      padding-top:150px;
    }
    .ill1 {
      text-align:center;
      img {
        position: relative;
        margin-left:-7%;
        max-width:110%;
      }
    }
    .ill2 {
      position: absolute;
      top: 33%;
      right: 80%;
      img {
        max-width:none;
        margin:0;
      }
    }
  }
  .slide4 {
    .chapter {
      padding-top:150px;
      margin-right:7%;
    }
    .ill img{
      position:relative;
      max-width: 115%;
    }
  }
}

/* ==========================================================================
VERDUN
========================================================================== */
.verdun {
  .chapter{
    padding-top:350px;
    padding-bottom:250px;
  }
  .slide1{
    // Avec degrade
    //background-color:$verdun;
    /*.bg-slide-blur {
      background-image:url("../img/verdun/verdun_nogradientbk-blur.jpg");
    }*/
    .bg-slide1 {
      background-image:url("../img/verdun/verdun_nogradientbk.jpg");
    }
  }
  .slide2 {
    background: url(../img/verdun/centenaire_bk.gif) 50% 90% no-repeat;
    .ill {
      img {
        top:-80px;
        /*visibility: hidden;
        top: 190px;*/
        /*left: 40%;*/
      }
    }
    .chapter {
    }
  }
  .slide3 {
    .chapter {
      padding-top:950px;
      padding-bottom:200px;
    }
    .ill1 {
      img {
        left:-75px;
      }
    }
    .ill2 {
      img {
        left:-50px;
        top: 390px;
      }
    }
  }
  .slide4 {
    .chapter {
      padding-top: 100px;
      padding-bottom: 350px;
      padding-left: 50px;
      box-sizing:content-box;
    }
    .ill img {
      max-width:85%;
    }
  }

  .ill1 img {
    top: 0px;
    left:-190px;
  }

}

/* ==========================================================================
AVI
========================================================================== */
.avi {
  .slide1{
    // Avec degrade
    //background-color:$avi;
    /*.bg-slide-blur {
      background-image:url("../img/avi/avi_bk-blur.jpg");
    }*/
    .bg-slide1 {
      background-image:url("../img/avi/avi_bk.jpg");
    }
    .device {
      margin-top: -30px;
    }
  }
  .slide2 {
    background: url(../img/avi/avi_lg_bk.png) 12% 55% no-repeat;
    max-width:1600px;
    .chapter {
      padding-top: 170px;
      padding-bottom: 300px;
    }
    .ill img {
      top:-20px;
    }
    .chapter {
    }
  }
  .slide3 {
    .chapter {
    }
    .ill1 img {
      position:relative;
    }
  }
}


/* ==========================================================================
CENTENAIRE
========================================================================== */
.centenaire {
  h1 {
    font-size:87px;
  }
  .chapter{
    padding-top:350px;
    padding-bottom:250px;
  }
  .slide1{
    // Avec degrade
    //background-color:$verdun;
    /*.bg-slide-blur {
      background-image:url("../img/centenaire/mission_bk-blur.jpg");
    }*/
    .bg-slide1 {
      background-image:url("../img/centenaire/mission_bk.jpg");
    }
  }
  .slide2 {
    background: url(../img/centenaire/logo-centenaire-bk.png) 50% 90% no-repeat;
    .ill {
      img {
        top:-80px;
        //left: auto;
        //right: -750px;
      }
    }
    .chapter {
      padding-bottom:500px;
    }
  }
  .slide3 {
    .chapter {
      //padding-top:950px;
      //padding-bottom:200px;
      padding-bottom:0;
      padding-top:270px;
      margin-right: 16%;
    }
    .ill1 {
      img {
        position: relative;
        left: -50px;
        max-width: 100%;
      }
    }
    .ill2 {
      img {
        position: relative;
        left: 0;
        top: 0;
        margin-top:160px;
      }
    }
  }
  .slide4 {
    .chapter {
      padding-top: 100px;
      padding-bottom: 350px;
      margin-right:16%;
    }
    .ill img {
      position:relative;
      left:-50px;
      margin-top: 100px;
      max-width:100%;
    }
  }
  .slide5 {
    .chapter {
      padding-top: 100px;
      padding-bottom:0;
    }
    .ill img {
      position:relative;
      top:0;
      left:50px;
      max-width:100%;
      float:right;
      //max-width:85%;
    }
  }

}


/* ==========================================================================
LEA
========================================================================== */
.lea {
  .chapter{
    padding-top:350px;
    padding-bottom:250px;
  }
  .slide1{
    // Avec degrade
    //background-color:$verdun;
    /*.bg-slide-blur {
      background-image:url("../img/lea/lea_bk-blur.jpg");
    }*/
    .bg-slide1 {
      background-image:url("../img/lea/lea_bk.jpg");
    }
  }
  .slide2 {
    .ill1 {
      position:absolute;
      top:50px;
      left:50px;
      margin-left:18%;
      margin-top: 3.5%;
      img {
        transform: translateX(-80px);
        position:relative;
        transition-delay: 0.3s;
        transition-duration: 0.9s;
      }
    }
    &.apparait {
      .ill1 {
        img {
          transform: translateX(0px);
        }
      }
    }
    .ill2 {
      position:absolute;
      top:50px;
      left:50px;
      img {
        position:relative;
      }
    }
    .chapter {
      padding-top:250px;
    }
  }
  .slide3 {
    background: url(../img/lea/logo-lea-bk.png) 50% 95% no-repeat;
    .chapter {
      padding-top:150px;
      padding-bottom:500px;
    }
    .ill {
      float:right;
      img {
        left:auto;
        right: -50px;
        top:-50px;
      }
    }
  }
  .slide4 {
    .chapter {
      padding-top: 100px;
      padding-bottom: 350px;
    }
    .ill img {
      left:-250px;
    }
  }

}


/* ==========================================================================
BDF
========================================================================== */

.banque{
  .slide1{
    // Avec degrade
    //background-color:$verdun;
    /*.bg-slide-blur {
      background-image:url("../img/lea/lea_bk-blur.jpg");
    }*/
    .bg-slide1 {
      background-image:url("../img/bdf/bdf_bk.jpg");
    }
  }
  .slide2{
    padding-top: 43px;
    .ill{
      text-align: center;
      padding-top: 50px;
      img{
        position: relative;
        max-width: 100%;
      }
    }
  }
  .slide3{
    .chapter{
      padding: 800px 0px 250px 0px;
    }
    .ill{
      img{
        top: -1400px;
        left: -550px;
      }
    }
  }
  .slide4{
    .ill1{
      img{
        max-width: 100%;
        top: -100px;
        //left: 600px;
        
      }
    }
    .ill2{
      img{
        max-width: 100%;
        top: 350px;
        left: -300px;
      }
    }
    .chapter{
      margin-top: 50px;
      padding-right: 60px;
      padding-bottom: 500px;
    }
  }
}

/* ==========================================================================
Fantasy
========================================================================== */
.fantasy{
  .bg-slide1{
    background-image: url(../img/fantasy/fantasy_bk-grad-blur.png);
  }
  .bg-slide-blur{
    img{
      display: none;
    }
  }
  .slide2{
    padding-top: 319px;
    padding-bottom:250px ;
    .chapter{
    p{
      a{
        position: relative;
        &::before{
          content: '';
          display: block;
          height: 1px;
          width: 0%;
          background:#343434;
          position: absolute;
          bottom: 0;
          left: 0;
          transition: all 0.5s ease-in-out;
        }
        &:hover{
          color:#343434;
          &:before{
            width: 100%;
          }
        }
      }
    }
    }
    .ill{
      img{
        position: absolute;
        left: 150px ;
        top: -270px;
        max-width: 895px;
      }
      .ill2{
        width: 170px;
        position: absolute;
        top: -380px;
        left: 750px;
        img{
          position: relative;
          top: auto;
          left: auto;
          width: 100%;
        }
      }
    }
  }
  .slide3{
    padding-top: 0px;
    padding-bottom:58px;
    .chapter{
      padding-top: 400px;
    }
    .ill1{
      img{
        position: relative;
        top: auto;
        left: auto;
        max-width: 357px;
        box-shadow: 10.5px 18.2px 60px 0 rgba(74, 74, 74, 0.2);
        margin-bottom: 29px;
        width: 55%;
      }
      .ill2{
        position: relative;
        max-width: 619px;
        img{
          margin-bottom: 0;
          width: 100%;
          max-width: 619px;
          &.flottante{
            position: absolute;
            top: -50%;
            right: -25%;
            max-width: 391px;
            width: 65%;
          }
        }
      }
    }
  }
  .slide4{
    .center-img{
      max-width: 393px;
      width: 100%;
      margin: 0 auto;
      img{
        width: 100%;
      }
    }
  }
  .slide5{
    padding-top: 41px;
    .ill{
      img{
        top: -41px;
        left: auto;
        right: 0;
      }
    }
  }
  .slide6{
    padding-top: 550px;
    padding-bottom: 505px;
    .ch-r{
      p{
        font-family: Gotham SSm A,Gotham SSm B,sans-serif;
        font-style: normal;
        font-weight: 300;
        font-size: 15px;
      }
    }
    .ill{
      img{
        top: -497px;
        left: -175px;
        z-index: -1;
      }
    }
  }
  .slide7{
    padding-bottom: 100px;
    margin: 0 25px;
    .max-content{
      max-width: 1900px;
      margin: 0 auto;
    }
    img.site-map{
      position: absolute;
      top: -300px;
      right: -25px;
      max-width: 812px;
      width: 100%;
    }
    .ill{
      padding-top: 188px;
      position: relative;
      img{
        &.long-img{
          max-width: 623px;
          width: 100%;
          position: relative;
          top: auto;
          left: auto;
          box-shadow: 10.5px 18.2px 106px 0 rgba(74, 74, 74, 0.43);
        }
      }
      .ill2{
        position: absolute;
        top: 500px;
        left: -700px;
        padding-top: 0;
        text-align: right;
        img{
          position: relative;
          display: inline-block;
          &.dwl{
            max-width: 227px;
            box-shadow: 10.5px 18.2px 106px 0 rgba(74, 74, 74, 0.43);
            z-index: 1;
          }
          &.cadre{
            margin-right: 170px;
            margin-top: -30px;
            display: block;
            max-width: 621px;
            box-shadow: 10.5px 18.2px 106px 0 rgba(74, 74, 74, 0.43);
          }
        }
      }
    }
  }
}

/* ==========================================================================
General
========================================================================== */
.hd {
  border-top: 1px solid #e6e6e6;
  border-bottom: 1px solid #e6e6e6;
}
.desc {
  padding: 30px 80px 30px 100px;
  position:relative;
  z-index:2;
  div:nth-child(4) {
    margin: auto;
    white-space:nowrap;
  }
}

.details{
  text-align: left;
  padding:0 20px;
  h3 {
    @include gotham-thin;
    margin-bottom:15px;
    margin-top:0;
  }
  p {
    font-size: 15px;
    padding-bottom:0;
    margin-bottom:0;
  }
  a:hover {
    border: 1px solid lightgrey;
    background-color: lightgrey;
  }
}

.show-mobile {
  display: none;
}
.show-desktop {
  display: block;
}
// NAV bottom: rollover selon sections
a#next:hover[href*="avi"], a#prev:hover[href*="avi"] {
  background-color: $avi;
}
a#next:hover[href*="bnp."], a#prev:hover[href*="bnp."] {
  background-color: $bnp;

}
a#next:hover[href*="atelier"], a#prev:hover[href*="atelier"] {
  background-color: $atelier;

}
a#next:hover[href*="verdun"], a#prev:hover[href*="verdun"] {
  background-color: $verdun;

}
a#next:hover[href*="dima"], a#prev:hover[href*="dima"] {
  background-color: $dima;
}
a#next:hover[href*="centenaire"], a#prev:hover[href*="centenaire"] {
  background-color: $centenaire;
}
a#next:hover[href*="lea"], a#prev:hover[href*="lea"] {
  background-color: $lea;
}
a#next:hover[href*="banque_de_france"], a#prev:hover[href*="banque_de_france"] {
  background-color: $banque;
}

a#next:hover[href*="bnf_fantasy"], a#prev:hover[href*="bnf_fantasy"] {
  background-color: $fantasy;
}
