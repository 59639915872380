// /* ==============================================
// TOC
//
// 1. Variables
// 2. Generic
// 3. Custom
//
// ===============================================**/
@import "base/typography";
@import "helpers/variables";
@import "helpers/mixins";
@import "partials/projets";

//reset Bootstrap

html {
  font-size: 16px;
  color: #343434;
}
// FIX fonts for Chrome
body {
  -webkit-animation-duration: 0.1s;
  -webkit-animation-name: fontfix;
  -webkit-animation-iteration-count: 1;
  -webkit-animation-timing-function: linear;
  -webkit-animation-delay: 0.1s;
}

@-webkit-keyframes fontfix {
  from { opacity: 1; }
  to { opacity: 1; }
}


/* ==========================================================================
GENERIC
========================================================================== */
body {
  overflow-x: hidden;
}
.fullpage {
  position:relative;
  &:before {
    content:'';
    position:fixed;
    top:0;
    left:100%;
    bottom:0;
    right:0;
    z-index:1100;
  }
  &:after {
    content:'';
    position:fixed;
    top:0;
    left:0;
    bottom:0;
    right:100%;
    z-index:1100;
    /*transition: all 1s cubic-bezier(0.2, 1, 0.3, 1);*/
  }
  &.pstep:before {
    left:0;
  }
  &.nstep:after {
    right:0;
  }
}

.previous-step {
  position:fixed;
  top:0;
  bottom:0;
  left:0;
  right:100%;
  z-index:1100;
  transition: all 1s cubic-bezier(0.2, 1, 0.3, 1);
  &.move {
    right:0;
  }
}
.next-step {
  position:fixed;
  top:0;
  bottom:0;
  left:100%;
  right:0;
  z-index:1100;
  transition: all 1s cubic-bezier(0.2, 1, 0.3, 1);
  &.move {
    left:0;
  }
}
#atelier {
  .previous-step {
    background-color:$fantasy;
  }
  .next-step {
    background-color:$bnp;
  }
  .fullpage:before, .fullpage:after {
    background-color:$atelier
  }
  &.pstep .fullpage:before {

  }
}
#bnp {
  .previous-step {
    background-color:$atelier;
  }
  .next-step {
    background-color:$dima;
  }
  .fullpage:before, .fullpage:after {
    background-color:$bnp
  }
}
#dima {
  .previous-step {
    background-color:$bnp;
  }
  .next-step {
    background-color:$verdun;
  }
  .fullpage:before, .fullpage:after {
    background-color:$dima
  }
}
#verdun {
  .previous-step {
    background-color:$dima;
  }
  .next-step {
    background-color:$avi;
  }
  .fullpage:before, .fullpage:after {
    background-color:$verdun
  }
}
#avi {
  .previous-step {
    background-color:$verdun;
  }
  .next-step {
    background-color:$fantasy;
  }
  .fullpage:before, .fullpage:after {
    background-color:$avi
  }
}
#centenaire {
  .previous-step {
    background-color:$avi;
  }
  .next-step {
    background-color:$lea;
  }
  .fullpage:before, .fullpage:after {
    background-color:$centenaire
  }
}
#lea {
  .previous-step {
    background-color:$centenaire;
  }
  .next-step {
    background-color:$banque;
  }
  .fullpage:before, .fullpage:after {
    background-color:$lea
  }
  
}

#fantasy {
  .previous-step {
    background-color:$avi;
  }
  .next-step {
    background-color:$atelier;
  }
  .fullpage:before, .fullpage:after {
    background-color:$fantasy;
  }
  
}

#banque {
  .previous-step {
    background-color:$lea;
  }
  .next-step {
    background-color:$atelier;
  }
  .fullpage:before, .fullpage:after {
    background-color:$banque
  }
  
}






.pstep .fullpage:before {
  right:0;
  /*transition: all 1s cubic-bezier(0.2, 1, 0.3, 1);*/
}
.nstep .fullpage:after {
  left:0;
  /*transition: all 1s cubic-bezier(0.2, 1, 0.3, 1);*/
}
h1, h2, h3, h4 .footer a{
  font-weight: 100;
  // @include roboto-thin;
  @include gotham-thin;
}
p, a {
  @include gotham-ss-light;
}
h1 {
  // font-size: 6vw;
  //font-size: 90px;
  color: #FFF;
  text-align: center;
  margin: 6rem 0 0 0;
}
h2 {
  font-size: 65px;
  @include gotham-thin;
  line-height: 70px;
}
h2, h3, p, a {
  color: #343434;
}
h3 {
  font-size:25px;
  @include gotham-ss-light;
  margin-bottom: 30px;
}
h4 {
  font-size:26px;
  @include gotham-ss-light;
  color: #232323;
  margin-top: 20px;
  margin-bottom: 5px;
  font-weight: 200 ;
}
a:link, a:visited, a:hover, a:active {
  text-decoration: none;
}
img {
  max-width: 100%;
}
.section  {
  position: relative;
}

.button {
  justify-content: flex-start;
  position:relative;
  display: table;
  margin: 1em 0;
  padding:10px 45px 10px 15px;
  border: none;
  background: none;
  color: inherit;
  border-radius: 0;
  font-size:12px;
  color:#343434;
  text-transform:uppercase;
  // font-family: 'Open Sans',sans-serif;
  @include gotham-ss-light;
  font-weight:500;
  // text-transform: uppercase;
  vertical-align: middle;
  position: relative;
  z-index: 1;
  -webkit-backface-visibility: hidden;
  -moz-osx-font-smoothing: grayscale;
  &:focus {
    color:#343434;
  }
  &--border-thin {
    /*border: 1px solid #343434;*/
    border:none !important;
    box-shadow: 0 0 0 1px #343434;
    &:focus {
      border-color:#343434;
    }
  }
  &--ujarak {
    -webkit-transition: border-color 0.4s, color 0.4s;
    transition: border-color 0.4s, color 0.4s;
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 5%;
      right:5%;
      // width: 101%;
      height: 100%;
      background: #343434;
      z-index: -1;
      opacity: 0;
      -webkit-transform: scale3d(0.7, 1, 1);
      transform: scale3d(0.7, 1, 1);
      -webkit-transition: -webkit-transform 0.4s, opacity 0.4s;
      transition: transform 0.4s, opacity 0.4s;
      -webkit-transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
      transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
      -webkit-transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
      transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);

    }
    &::after {
      content: url('../img/arrow.svg');
      margin-right: 20px;
      width: 17px;
      position: absolute;
      top: 11px;
      right: 0;

      // background-size: 17px 28px;
      // width: 50px;
    }

    &:hover {
      color: #fff;
      //border-color: #343434;
    }
    .button--inverted:hover {
      color: #343434;
      border-color: #fff;
    }
    &:hover::before {
      opacity: 1;
      left:0;
      right:0;
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }
    &:hover::after {
      // content: url('../img/right_w.png');
      filter: invert(100%);
    }
  }
  .button--inverted::before {
    background: #7986CB;
  }
}
.tirets {
  position: relative;
  display:table;
}
.tirets:before {
  padding:0;
  margin:-8px 0;
  display:block;/* probably not really needed? */
  content: "";
  width:40px;
  height:2px;/* slight higher to work around rounding errors(?) on some zoom levels in some browsers. */
  background-color:#8ec348;
  position: absolute;
  left:0;
  top:0px;
}
.tirets:after {
  padding:0;
  margin-top:7px;
  display:block;/* probably not really needed? */
  content: "";
  width:40px;
  height:2px;/* slight higher to work around rounding errors(?) on some zoom levels in some browsers. */
  background-color:#8ec348;
  position: absolute;
  right: 50%;
  margin-right: -20px;
}
/* ==========================================================================
CUSTOM
========================================================================== */

.vertical-align {
  display: flex;
  align-items: center;
}
.cont-reduit {
  width: 70%;
}
.nopadding {
  padding: 0!important;
}
.flex-1 {
  flex-basis: auto;
}
.flex {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
// POUR FORCER LES SAUTS DE LIGNE DANS LES TITRES
.rwd-break {
    display: block;
}
/* ==========================================================================
MENU
========================================================================== */
.fullpage {
  overflow:hidden;
}
.hp {
  h3 {
    font-size: 30px;
    font-weight: 300;
  }
  h3.tirets {
    opacity:0;
    transition:opacity 0.3s ease;
    &.view {
      opacity:1;
    }
  }
  h3.tirets:after, h3.tirets:before {
    width:0;
    transition:width 0.4s ease 0.3s;
  }
  h3.tirets.view:before, h3.tirets.view:after {
    width:40px;
  }

  nav {
    .menu-ham, .line{
      border-color: #343434;
      -webkit-transition: .25s ease-in-out;
      -moz-transition: .25s ease-in-out;
      -o-transition: .25s ease-in-out;
      transition: .25s ease-in-out;
      }
    .logo img {
      // filter: invert(100%);
      // -webkit-filter: invert(100%);
      // -webkit-transition: .25s ease-in-out;
      // -moz-transition: .25s ease-in-out;
      // -o-transition: .25s ease-in-out;
      // transition: .25s ease-in-out;
      }
  }
}

.bloc-menu-mobile {
  text-align:center;
  position:fixed;
  left:0;
  right:0;
  top:0;
  bottom:0;
  background-color:#ffffff;
  visibility:hidden;
  transform:translateX(-100%);
  transition:visibility 0s linear 0.5s, transform 0.5s ease-in-out 0s;
  .back-level1 {
    position:absolute;
    left:15px;
    top:15px;
    transform: rotate(180deg);
    height: 40px;
    width: 40px;
    z-index:1;
    img {
      margin-top:12px;
    }
  }
  .item-mobile-inner {
    position:absolute;
    width: 100%;
    padding: 0 15px;
    top:50%;
    left:50%;
    transform:translateX(-50%) translateY(-50%);
    h4 {
      @include gotham-thin;
      font-size:40px;
      margin-bottom:40px;
      font-weight:300;
    }
    p {
      font-size: 16px;
      line-height: 28px;
      font-weight:200;
      &.first {
        position:relative;
        padding-bottom:25px;
        display:table;
        margin:0 auto 25px;
        &:after {
          position: absolute;
          bottom: 0;
          left: 50%;
          height: 3px;
          width: 40px;
          background-color:#8ec348;
          content: '';
          transform: translateX(-50%);
        }
      }
    }
    a:hover, a:focus {
      color:inherit;
    }
  }
  .menu-mobile-wrapper {
    position: relative;
    max-height: 100%;
    transform: translateY(-50%);
    top: 50%;
    overflow: auto;
    padding: 50px 0;
    .item-mobile-inner {
      position:relative;
      top:0;
      left:0;
      transform:none;
    }
  }
  &.menu-contact {
    .item-mobile-inner {
      p.first {
        text-align: justify;
      }
    }
  }
  &.view {
    transform:translateX(0);
    visibility:visible;
    transition:visibility 0s linear 0s, transform 0.5s ease-in-out 0.1s;
  }
  &.menu-projets {
    ul {
      padding:0;
      margin:0;
      list-style-type:none;
      li .projet-title {
        margin-bottom:20px;
        a {
          position: relative;
          left: 0px;
          display:inline-block;
          font-size:22px;
          font-weight:100;
        }
      }
    }
  }
}

nav {
  width:0;
  max-width: 100%;
  position: fixed;
  z-index: 1000;
  .menu-container {
    .menu-desktop {
      display:block;
      position: absolute;
      width:100%;
      max-height: 100%;
      top: 50%;
      transform: translateY(-50%);
      .mCustomScrollBox {
        padding-left: 50px;
        margin-left: -50px;
        max-width:none;
        width: calc(100% + 50px);
      }
      .mCSB_container {
        padding-left: 50px;
        margin-left: -50px;
      }
      .projects-list {
        max-height:calc(100vh - 100px);
        padding-bottom:30px;
      }
    }
    .menu-mobile {
      display:none;
      .logo-mobile {
        position:absolute;
        top:10px;
        left:10px;
        img {
          width:60px;
        }
      }
      .menu-mobile-inner {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translateX(-50%) translateY(-50%); 
        ul {
          padding:15px;
          li {
            .projet-title {
              text-align:center;
            }
            padding:5px 0;
            a {
              @include gotham-ss-light;
              font-weight:200;
              font-size:26px;
              &:before {
                /*left:-50px;
                top:0;*/
                display:none;
              }
              &:hover:after {
                left:-35px;
              }
            }
          }
        }
      }
      .copyright {
        text-align:center;
        width:100%;
        padding:0 15px;
      }
    }
  }
  .nav-wrapper {
    position: relative;
    width:100px;
    top: 30px;
    left: 30px;
    z-index:2;
    box-shadow:0px 25px 86px 15px rgba(0,0,0,0.25);
    -webkit-box-shadow:0px 25px 86px 15px rgba(0,0,0,0.25);
  }
  .logo {
    position:relative;
    float: left;
    width: 60px;
    height: 60px;
    transition: 1s;
    padding: 7px;
    background-color:#515151;
    -webkit-transition: .25s ease-in-out;
    -moz-transition: .25s ease-in-out;
    -o-transition: .25s ease-in-out;
    transition: .25s ease-in-out;
    svg {
      position:relative;
      z-index:1
    }
    svg path {
      fill:#ffffff;
    }
  }
  a.logo:after {
    content:'';
    position:absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
    background: #9cca06; /* For browsers that do not support gradients */
    background: -webkit-linear-gradient(top, #9cca06, #67b517); /* For Safari 5.1 to 6.0 */
    background: -o-linear-gradient(top, #9cca06, #67b517); /* For Opera 11.1 to 12.0 */
    background: -moz-linear-gradient(top, #9cca06, #67b517); /* For Firefox 3.6 to 15 */
    background: linear-gradient(to bottom, #9cca06, #67b517); /* Standard syntax */
    opacity:0;
    transition:all 0.3s ease;
  }
  a.logo:hover:after {
    opacity:1;
  }
  .menu-ham {
    float: left;
    margin-left: -1px;
    margin-left: 0px;
    width: 40px;
    height: 40px;
    background-color:#ffffff;
    border-left: 0;
    padding: 10px;
    transition: 1s;
    -webkit-transition: .25s ease-in-out;
    -moz-transition: .25s ease-in-out;
    -o-transition: .25s ease-in-out;
    transition: .25s ease-in-out;
    cursor: pointer;
    .ham-cont {
      position: relative;
      top:0;
      left:0;
      width: 100% ;
      height: 100%;
      #line1 {
        top: 0px;
        width: 15px;
      }
      #line2 {
        top: 8px;
        width: 19px;
      }
      #line3 {
        top: 16px;
        width: 17px;
      }
    }  
    .line {
      display: block;
      position: absolute;
      height: 2px;
      background: #515151;
      border-radius: 9px;
      opacity: 1;
      left: 0;
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
      -webkit-transition: .25s ease-in-out;
      -moz-transition: .25s ease-in-out;
      -o-transition: .25s ease-in-out;
      transition: .25s ease-in-out;
    }
  }
  .tirets:before, .tirets:after {
    background-color: $vert;
   }
  .darker {
    img {
      filter: invert(100%);
      -webkit-filter: invert(100%);
    }
    border-color: #000;
    &.menu-ham .line {
      background-color: #000;
    }
  }
  .menu-container {
    background-color: rgba(48,48,48,1);
    width: 0;
    height: 0;
    position:relative;
    top:30px;
    left:30px;
    transition: all .9s cubic-bezier(.18,1,.21,1), top 0.3s ease, left 0.3s ease;
    //
    .menu-ouvert {
      visibility:hidden;
      position: relative;
      margin:auto;
      max-width:900px;
      .menu-logo {
        margin-bottom: 100px;
        text-align: left;
      }
    }
    ul {
      padding:0;
    }
    .menu-left ul li {
      margin-left:-10%;
      opacity:0;
    }
    .item-menu-right ul {
      opacity:0;
      margin-left:-10%;
    }
    // margin-left: 400px;
    .title-section {
      font-size: 30px;
      // @include roboto-thin;
      @include gotham-ss-light;
      color: #FFF;
      text-align: left;
      margin-bottom: 50px;
      font-weight: 200;
      opacity:0;
      &:before, &:after {
        width:0;
      }
    }
    .projet-title {
      text-align: left;
      a {
        position: relative;
        left: 0px;
        display:inline-block;
        // @include roboto-thin;
        @include gotham-thin;
        font-size: 40px;
        color: #FFF;
        .bigger {
          position:relative;
          left:0;
          transition:all .5s ease-out;
        }
        &:before {
          content: url('../img/arrow-green.svg');
          width: 22px;
          position: absolute;
          left: -36px;
          top: -5px;
          opacity:0;
          margin-left:0;
          transition: .5s ease-out;
        }
        &:hover {
          &:before {
            opacity:1;
            margin-left:35px;
          }
          .bigger {
            left:35px;
          }
          .smaller {
            color:#ffffff;
          }
          // margin-left: 50px;

          // &:before {
          //   content: url('../img/arrow.svg');
          //   margin-left: 6px;
          //   width: 16px;
          //   position: absolute;
          // }
        }
      }
      span.smaller {
        display: block;
        position: relative;
        left:35px;
        font-size: 15px;
        /*color: #FFF;
        opacity: 0.5;*/
        color: #9c9c9c;
        @include gotham-ss-light;
        font-weight: 200;
        transition:all 0.3s ease-in-out;
        // color: #979797;
        &.active {
          color: #FFF;
        }
      }
    }
    aside {
      padding-top: 0px;
      padding-bottom:60px;
      text-align: left;
      h4.title-section {
        margin-bottom: 30px!important;

        margin-top: 0;
      }
      .contact {
        margin-top: 80px!important;
      }
      p {
        font-size: 15px;
        color: #bebebe;
        font-weight: 200;
        a {
          color: #bebebe;
          &:hover {
            color: #bebebe;

          }
        }
      }
      .adresse {
        font-size: 14px;
        line-height: 24px;
        margin-bottom: 20px;
        font-weight: 200;
      }
    }
    .copyright {
      color: #bebebe;
      font-size: 15px;
      text-align: left;
      position:absolute;
      bottom:0;
    }
    &.opened {
      width: 100vw;
      height: 100vh;
      left:0;
      top:0;
      h2 {
        display: block;
      }
      .menu-ouvert{
        height:100%;
        /*transition: 2s ease-out;*/
        visibility:visible;
      }
      .menu-left ul li {
        transition:all 1s ease;
        @for $i from 1 through 20 {
          &:nth-child(#{$i}) { 
            transition-delay: (0.1s + ($i / 10));
          }
        } 
        opacity:1;
        margin-left:0;
      }
      .item-menu-right {
        @for $i from 1 through 5 {
          &:nth-child(#{$i}) ul {
            transition:all 1s ease;
            transition-delay: (0.3s + ($i / 10));
            opacity:1;
            margin-left:0;
          }
        }
      }
      .title-section {
        opacity:1;
        transition:all 0.4s ease 0.3s;
        &:before, &:after {
          width: 40px;
          transition: width .4s ease .6s;
        }
      }
    }
  }
  &.open {
    width:auto;
  }
  &:not(.open) .line {
    transition:all 0.3s ease;
  }
  &:not(.open) .menu-ham:hover .line {
    width:19px !important;
  }
  &.open .menu-ham {
    border-color: #FFF;
    border: 1px solid #FFF;
    background-color:transparent;
  }

  &.open .menu-ham .ham-cont{
    #line1 {
      top: 8px;
      width: 25px;
      height: 2px;
      left: -3px;
      background-color: #FFF;
      -webkit-transform: rotate(135deg);
      -moz-transform: rotate(135deg);
      -o-transform: rotate(135deg);
      transform: rotate(135deg);
    }
    #line2 {
      opacity: 0;
      // left: -60px;
    }
    #line3 {
      top: 8px;
      width: 25px;
      height: 2px;
      left: -3px;
      background-color: #FFF;
      -webkit-transform: rotate(-135deg);
      -moz-transform: rotate(-135deg);
      -o-transform: rotate(-135deg);
      transform: rotate(-135deg);
    }
  }
}
#logo {
  width: 200px;
  /*height: 200px;*/
  height:auto;
  .cls-3 {
    opacity: 0.3;
    transition: 1s;
  }
  .cls-3:hover {
    opacity: 1;
  }
}

.chapo {
  @include gotham-ss-light;
  font-size:17px;
  color: #343434;
  margin: 2.8rem auto;
  text-align: center;
  p {
    font-weight:300;
    line-height:28px;
  }
  .keyword {
    color:#7bbd10;
    font-weight:400;
  }
}

/* ==========================================================================
HOMEPAGE
========================================================================== */
.hp {
  .chapo {
    width: 100%;
    margin-top:50px;
  }
  .cont-projets {
    width: 1025px;
  }
  /*&.fixed .slide1 {
      padding-bottom: 100vh;
  }*/
  h3 {
    margin-bottom:45px;
  }
  .desc-projet {
    p {
      font-size: 16px;
      color: #787878;
      margin-bottom: 30px;
    }
    a {
      margin-top: 20px;
    }
  }
  .cont-s1 {
    align-items: center;
  }
  .header-inner {
    width:100%;
  }

  &.fixed {
    .cont-s1 {
      position:fixed;
      left:15px;
      right:15px;
      padding:0;
      width:auto;
    }
  }
  .h1-cont {
    position: relative;
  }
  h1 {
    margin-top:0;
    .esperluette {
      position: absolute;
      width: 130px;
      top: 0px;
      left:50%;
      transform: translateX(-50%);
      margin-top:-40px;
    }
    .baseline {
      position: absolute;
      clip: rect(70px,500px,120px,0px);
      top: 135px;
    }
    .iseabloom {
      /*position: absolute;
      clip:rect(0,500px,111px,0px);
      top: 80px;*/
    }
    .mots-intro {
      position:relative;
      width:100%;
      max-width:930px;
      margin:auto;
      height:70px;
      transition:opacity 0.3s ease;
      &.end {
        opacity:0;
      }
    }
    .mot-left, .mot-right{
      // @include roboto-thin;
      @include gotham-thin;
      color: #000;
      position: absolute;
      font-size: 40px;
      width: 400px;
      // border: 1px solid red;
      opacity:0;
      text-transform:lowercase;
    }

// V1 descente esperluette
    .mot-left {
      // display: none;
      left:0;
      text-align: right;
      top:0;
    }
    .mot-right {
      // display: none;
      right:0;
      text-align: left;
      top:100%;
    }
  }
  a.zoom {
    transform: translateY(0);
    .level1 {
      .level2 {
        transition: transform .3s ease-in-out,-webkit-transform .3s ease-in-out;
        overflow:hidden;
      }

      img {
        transition: transform .3s ease-in-out,-webkit-transform .3s ease-in-out;
        height:auto;
      }
    }

    &:hover {
      .level2 {
          // transform: matrix(1, 0, 0, 1, 0, 0);
          transform: scale(.97);
          overflow:hidden;
        img {
          transform: scale(1.1);
        }
      }

    }
  }
  .projets {
    padding-top:0;
    transition:all 0.3s ease-in-out;
  }
  &.supoffset .projets {
    padding-top:50px;
  }
  .footer {
    // background-color: rgba(48,48,48,1);
    // padding: 20px;
    h4 {
      // color: $vert;
      // @include roboto-thin;
      // @include gotham-thin;
    }
    p, a {
      // color: #FFF;
      // font-family: 'Open Sans',sans-serif;
        // @include gotham-thin;
    }
    .contact-container {
      justify-content: center;
    }
    .menu-logo {
      width: 310px;
    }
  }
  .slide1 {
    overflow: hidden;
  }
  .logo-text {
    position:relative;
    padding:10px 0 25px;
    line-height:0;
    display:table;
    margin:auto;
    img {
      opacity:0;
      transition: all 0s ease 0s;
    }
  }
  &.scrolled {
    .logo-text img {
      transition: all 0.5s ease 0.6s;
      opacity:1;
    }
  }
  .logo-text:before,
  .logo-text:after {
    content:"";
    position:absolute;
    height:2px;
    background-color:#515151;
    transition:all 0.5s ease 1.1s;
    width:0;
  }
  .logo-text:before {
    top:0;
    left:0;
  }
  .logo-text:after {
    bottom:0;
    right:50%;
    margin-right:-61px;
  }
  &.scrolled {
    .logo-text:before {
      width:85px;
    }
    .logo-text:after {
      width:100px;
    }
  }
  .iseabloom,.baseline {
    /*display: none;*/
    /*opacity: 0;*/
  }
  .nav {
    visibility:hidden;
    opacity:0;
    transition:visibility 0s linear 0s, opacity 0s ease-in-out 0s;
  }
  &.scrolled {
    .nav {
      transition:visibility 0s linear 0s, opacity 0.4s ease-in-out 0.1s;
      opacity:1;
      visibility:visible;
    }
  }
  .chapo {
    opacity:0;
    transition:all 0s ease 0s;
  }
  &.scrolled {
    .chapo {
      transition:all 0.5s ease 1s;
      opacity:1;
    }
  }
  .cont-s1 {
    height: 100%;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    width: 100%;
    -ms-flex-align: center;
    -webkit-align-items: center;
    -webkit-box-align: center;
    align-items: center;
  }
  .h1-cont {
    margin: auto;
    top:100px;
    transition:all 0.8s ease;
  }
  &.refferer {
    .h1-cont {
      top:0;
      transition:none;
    }
  }
  &.scrolled {
    .h1-cont {
      top:0;
    }
  }
  h1 .mot-left {
    display: block;
  /*opacity: 1;*/
    top: -20px;
  }
  h1 .mot-right {
    top: 80px;
    display: block;
  /*opacity: 1;*/
  }
  .slide1 {
    height:100vh;
  }
  #logo {
    width: 130px;
    overflow:hidden;
  }
  #logo path{
    // stroke:#93C021;
    // fill:#93C021;
    // fill-opacity:0;
    // stroke-width:2;
    // stroke-dasharray: 800;
    // stroke-dashoffset:800;
    // -moz-animation:DASH3 3s ease-in-out 1s forwards;
    // -webkit-animation:DASH3 3s ease-in-out 1s forwards;
    // animation:DASH3 3s ease-in-out 1s forwards;
  }
  @-webkit-keyframes DASH3{
    0%  {stroke-dashoffset:800; fill-opacity:0;}
     80%  {stroke-dashoffset:200; fill-opacity:0;}
    100%{stroke-dashoffset:0; fill-opacity:1;}
  }
/*@-moz-keyframes DASH3{
    0%  {stroke-dashoffset:800;fill-opacity:0;}
     80%  {stroke-dashoffset:100; fill-opacity:0.8;}
    100%{stroke-dashoffset:100;fill-opacity:.5;}
}*/

}
.ct-scrolldown {
  //display:table;
  width:100%;
  text-align:center;
  position:absolute;
  bottom:20px;
  left:50%;
  transform:translateX(-50%);
  opacity:0;
  .button {
    //display:inline-block;
    display:table;
    margin:auto;
    padding:10px 15px;
    visibility:hidden;
    opacity:0;
    transition:opacity 0.4s ease 1.1s, visibility 0s linear 1s;
    &:after {
      display:none;
    }
  }
}
.scrolled {
  .ct-scrolldown {
    .button {
      opacity:1;
      visibility:visible;
    }
  }
}
.refferer .ct-scrolldown {
  animation-delay:1s;
}
.flecheScrolling {
  width: 30px;
  font-size: 33px;
  font-weight: normal;
  transform: rotate(180deg);
  margin:50px auto 0;
  position:relative;
  display:block;
  visibility:visible;
  transition:opacity 0.4s ease 0.1s, visibility 0s linear 0s;
  img {
    transform:rotate(-90deg);
    position: relative;
    //left: 13px;
    left:0;
  }
}
.scrolled .flecheScrolling img {
  left:0;
}
.refferer .flecheScrolling {
  animation-delay:1.3s;
}
.supoffset .flecheScrolling {
  opacity:0;
  visibility:hidden;
  transition:opacity 0.4s ease 0s, visibility 0s linear 0.4s;
}
.esperluette-color {
  width: 111px;
}
.isea-motto {
  width: 385px;
}
.grid {
  display: grid;
}
.liste-projets {
  padding-top:120px;
}
.projet-hp {
  width: 470px;
  float: left;
  margin-bottom: 110px;
  margin-left:50px;
  transform:translateY(50px);
  transition:transform 1s ease 0.2s;
  &.projet-hp:nth-child(2n+1) {
    margin-top:-120px;
    margin-left:0;
  }
  .level1 {

  }
  .level3 {
    opacity:0;
    transform:scale(1.3);
    transition:opacity 0.3s ease-in-out, transform 0.5s ease-in-out 0s;
  }
}
.projet-hp.view {
  transform:translateY(0);
  .level3 {
    opacity:1;
    transform:scale(1);
  }
}
.cadre-projet {
  width: 475px;
  height: 675px;
  position: relative;
  overflow: hidden;
  img {
    transform: scale(0.95);
    position: absolute;
    transition: transform .5s;
    &:hover {
      transform: scale(1);
    }
  }
  &.atelier {
    background-color: #f8ca99;
    .ill1 {
      top: 160px;
      left: 75px;
      z-index: 100;
    }
    .ill2 {
      top: 25px;
      left: 25px;
      z-index: 50;
    }
  }
  &.dima {
    background-color: #bec4d1;
    .ill1 {
      top: 260px;
      left: -20px;
      z-index: 100;
    }
    .ill2 {
      top: 25px;
      left: 140px;
      z-index: 50;
    }
  }
  &.bnp {
    background-color: #85ccb1;
    .ill1 {
      top: 260px;
      left: 110px;
      z-index: 100;
    }
    .ill2 {
      top: 35px;
      left: 30px;
      z-index: 50;
    }
  }
  &.verdun {
    background-color: #a6d2d6;
    .ill1 {
      top: 260px;
      left: 110px;
      z-index: 100;
    }
    .ill2 {
      top: 35px;
      left: 30px;
      z-index: 50;

    }
  }
  &.avi {
    background-color: #cbacd3;
    .ill1 {
      top: 125px;
      left: 10px;
      z-index: 100;
    }
    .ill2 {
      top: 40px;
      left: 260px;
      z-index: 50;
    }
  }




}



/* ==========================================================================
NAV FOOTER
========================================================================== */
.footer-nav {
  position:relative;
  z-index:2;
}
.nav-bottom  {
  // display: flex;
  // justify-content: center;
  width: 1000px;
  margin: auto;
  /*
  align-items: center;
  flex-wrap: wrap;*/
  div {
    // width: 500px;
    // height: 185px;
    float:left;
  }
  a {
    @include gotham-thin;
    margin: 0;
    // padding: 70px 35px;
    // font-size: calc(1em + 1vw);
    font-size: 30px;
    // flex: 1;
    width: 50%;
    float:left;
    height: 185px;
    text-align: center;
    padding: 70px 15px;
    display: inline-block;
    transition: background-color 1s;
    position: relative;
    &#prev {
      /*transition: color .2s;*/
      background-color: #303030;
      color: #FFF;
      &:hover {
        // background-color: #cccccc;
        // color: #000;
        &::before {
          transition: 0.6s ease-out;
        }
      }
      &::before {
        content: url('../img/fleche-fine-bl.svg');
        // margin-right: 10px;
        position: absolute;
        bottom: 20px;
        left: 45px;
        width: 30px;
        height: 10px;
        transform: rotate(180deg);
      }
    }
    &#next {
      background-color: #212121;
      color: #FFF;
      &:hover {
        // background-color: #cccccc;
        // color: #000;
        &::after {
          transition: 1s ease-out;
        }
      }
      &::after {
        content : url('../img/fleche-fine-bl.svg');
        position: absolute;
        bottom: 56px;
        right: 43px;
        width: 30px;
        height: 10px;
      }
    }
    span {
      position:absolute;
      top:50%;
      left:50%;
      transform:translateX(-50%) translateY(-50%);
      padding:0 15px;
      display:block;
      width:100%;
    }
  }
}
a#prev {
  // transition: background-color 1s;
  // /*transition: color .2s;*/
  // background-color: #303030;
  // color: #FFF;
  // text-align: right;
  // position: relative;
}
a#prev:hover, a#next:hover {
  // background-color: #cccccc;
  // color: #000;
}
a#prev::before {
  // content: url('../img/arrow.png');
  // margin-right: 10px;
  // transform: rotate(90deg);
}
a#next {
  // transition: background-color 1s;
  // background-color: #212121;
  // text-align: left;
  // color: #FFF;
  // position: relative;
}
#next::after {
  // content: url('../img/right_bg_w.png');
  // position: absolute;
  //   bottom: 20px;
  //   left: 384px;
  //   filter: blur(1px);
}
.vers-top {
  text-align: center;
  width: 1000px;
  margin: auto;
  a {
    font-size: 22px;
    font-weight: 200;
    &:hover, &:focus {
      color:#787878;
    }
    img {
      margin-top: 10px;
      transform:rotate(-90deg);
      // width: 30px;
      // transform: rotate(270deg);
    }
  }

}

/* ==========================================================================
INIT animation
========================================================================== */
.slide1 .device {
  /*display: none;*/
  opacity: 0;
  transform: translate3d(0, 100%, 0);
  transition:all 1s ease-in-out;
}
.slide1 .device.view {
  /*display: none;*/
  opacity: 1;
  transform: translate3d(0, 0, 0);
}
.hd {
  // height: 2px;
}
.hd .details {
  // opacity: 0;
}
.details {
  // display: none;
}
.det1, .det2, .det3, .det4 {
  opacity:0;
}
.det1, .det2, .det3, .det4 {
  -webkit-animation-duration: 1s;
  -moz-animation-duration: 1s;
}
.det1 {
  -webkit-animation-delay: 0s;
  -moz-animation-delay: 0s;
}
.det2 {
  -webkit-animation-delay: .3s;
  -moz-animation-delay: .3s;
}
.det3 {
  -webkit-animation-delay: .5s;
  -moz-animation-delay: .5s;
}
.det4 {
  -webkit-animation-delay: .7s;
  -moz-animation-delay: .7s;
}
.section .chapter {
  position:relative;
  z-index:1;  
}
.slide2, .slide3, .slide4 {
  opacity:0;
  transition: opacity 2s;
}
.slide2.apparait, .slide3.apparait, .slide4.apparait {
  opacity: 1;
}

#prev, #next {
  -webkit-animation-duration: 1s;
  -moz-animation-duration: 1s;
}
#next {
  float:right;
}

// BIG RESET TEST
// .ill img {
//   position: relative;
// }


// IE
.ie {
  .logo-top {
    top:4px;
  }
  .button {
    &--ujarak {
      &:hover::after {
        // content: url('../img/right_w.png');
        content: url('../img/fleche-fine-bl.svg');
      }
    }
  }
}

.lte-ie9 {
  .hp {
    .header-inner {
      position:absolute;
      top:50%;
      margin-top:-200px;
    }
    .ct-scrolldown {
      transform:none;
      position:static;
    }
    &.scrolled {
      .esperluette-shadow {
        opacity:1;
      }
      .ct-scrolldown {
        opacity:1;
      }
    }
  }
  .button--ujarak:hover {
    background-color:#343434;
    color:#ffffff;
  }
  .button--ujarak::before {
    display:none;
  }
  .footer-contact .contact-container .contact-sub {
    float:left;
  }
  .footer-contact p {
    float:left;
  }
  .projet {
    .container .motto {
      opacity:1;
    }
    .chapo {
      float:none;
    }
    .det1, .det2, .det3, .det4 {
      opacity:1;
    }
  }
}

.edge {
  .nav-bottom a#next::after {
    width:34px;
    height:20px;
  }
  .nav-bottom a#prev::before {
    width:34px;
    height:20px;
  }
}
