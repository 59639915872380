/* ==========================================================================
RESPONSIVE
========================================================================== */

// Gouttieres PROJETs


/****** MAX HEIGHT *****/
@media(max-height:700px) {
  .hp {
    h1 {
      padding:0 15px;
      #logo {
        /*transform: translateX(-50%) scale(0.8);*/
        width:120px;
        margin-top:-50px;
      }
    }
    .logo-text {
      width:250px;
      padding: 10px 0 18px;
      margin-top:-25px;
    }
    .ct-scrolldown {
      bottom:0;
    }
    .logo-text:after {
      margin-right:-42px;
    }
    .chapo {
      font-size:16px;
      margin-top: 20px;
    }
    .flecheScrolling {
      margin:15px auto 0;
      width: 22px;
    }
    &.scrolled {
      .logo-text:before {
        width:63px;
      }
      .logo-text:after {
        width:70px;
      }
    }
  }
}
@media(max-height:450px) {
  .hp {
    .chapo {
      margin: 25px auto 20px;
    }
    h1 {
      margin-top: 60px;
    }
    .ct-scrolldown {
      position:static;
      transform:none;
      margin:auto;
    }
  }
}


/****** MIN WIDTH *****/
@media(min-width:992px) {
  .leprojet {
    .det4 {
      position:static;
    }
    .button {
      position:absolute;
      top:50%;
      transform:translateY(-50%);
    }
  }
  //Fantasy
  .fantasy{
    .slide5{
      .chapter{
        h2{
          min-width: 345px;
        }
      }
    }
  }
}
@media (min-width: 1400px) {
  .degrade {
    margin-top: -220px;
  }
  .banque{
    .slide3{
      position: relative;
      .chapter{
        position : relative;
        left: -10%;
        top: -20px;
        
      }
    }
  }
}
@media (min-width: 1600px) {
  .degrade {
    margin-top: 0;
  }
  .banque{
    .slide3{
      position: relative;
      .chapter{
        position : relative;
        left: -10%;
        top: -50px;
        
      }
    }
  }
  .fantasy{
    .slide6{
      .ch-r{
        &.col-lg-offset-7{
          margin-left: 40%;
        }
      }
    }
  }
}

@media(min-width:1750px) {
  .atelier {
    .slide2 {
      .ill img {
        top:-80px;
      }
    }
  }
  .dima {
    .slide4 {
      .chapter {
        width: 33.33333%;
        margin-right: 13%;
      }
      .ill {
        width:48%;
      }
    }
  }
  .verdun {
    .slide4 {
      .chapter {
        margin-left: 58.33333333%;
      }
    }
  }
}

@media(min-width:1940px) {
  .bnp {
    .slide2 {
      .chapter {
        margin-top:300px;
      }
      .ill img {
        margin-top:0;
      }
    }
  }

}
@media(max-width:1939px) {
  .bnp {
    .slide2 {
      .chapter {
        float:none;
      }
    }
  }
}




/******* MAX WIDTH ******/
@media(max-width:1600px) {
  .dima {
    .slide2 {
      .chapter {
      }
      .ill img {
        top:-40px;
      }
    }
  }
}
@media(min-width:1200px) and (max-width:1500px) {
  .atelier {
    .slide2 {
      .ill img {
        top:-15px;
        max-width: 140%;
        left: -40%;    
      }
    }
  }
  .bnp {
    .slide3 {
      .chapter {
        padding-top:900px;
        padding-bottom:50px;
      }
    }
  }
  .dima {
    .slide2 {
      .chapter {
        padding-top:700px;
        padding-bottom:70px;
      }
    }
  }
  .lea {
    .slide2 {
      .ill1 img {
        max-width:115%;
      }
      .ill2 img {
        max-width:125%;
      }
      .chapter {
        padding-bottom:10%;
      }
    }
    .slide3 {
      .ill img {
        max-width: 120%;
        right:-15%;
      }
      .chapter {
        padding-top:4%;
      }
    }
    .slide4 {
      .ill img {
        max-width:80%;
      }
      .chapter {
      }
    }
  }
  .fantasy{
    .slide5{
      .ill{
        img{
          right: auto;
          left: 0;
        }
      }
    }
  }
}

@media (max-width: 1425px){
  .fantasy{
    .slide7{
      .ill{
        .ill2{
          left: -550px;
          top: 450px;
          img{
            &.cadre{
              max-width: 500px;
            }
          }
        }
      }
    }
  }
}

@media(min-width:1200px) and (max-width:1400px) {
  .avi {
    .slide2 {
      background-position:8% 70%;
      .ill img {
        left:-120px;
      }
    }
  }
}

@media(max-width:1400px) {
  .bnp {
    .slide2 {
      .ill img {
        max-width:100%;
      }
    }
  }
  .dima {
    .slide3 {
      .ill1 {
        img {
        }
      }
      .ill2 {
        width:100%;
        top: 40%;
        right: 75%;
        img {
          max-width:80%;
        }
      }
    }
  }
}
@media(max-width:1199px) {
  .hp.supoffset .projets {
    padding-top: 100px;
  }
  .atelier {
    .slide4 {
      .chapter {
        padding-top:600px;
      }
    }
  }
  .bnp {
    .slide2 {
      .chapter {
      }
      .ill img {
        margin-top:0;
      }
    }
    .slide3 {
      .chapter {
        padding-bottom:100px;
      }
    }
    .slide4 {
      .chapter {
        padding-top:10%;
      }
      .ill img{
        left:-60px;
      }
    }
  }
  .fantasy{
    .slide2{
      .ill{
        img{
          max-width: 700px;
          top: -300px;
        }
        .ill2{
          width: 120px;
          left: 580px;
        }
      }
    }
    .slide3{
      .chapter{
        padding-top: 300px;
      }
    }
    .slide5{
      .ill{
        img{
          max-width: 115%;
          right: auto;
          left: 0;
        }
      }
    }
    .slide6{
      .ill{
        img{
          left: -200px;
          max-width: 750px;
        }
      }
    }
    .slide7{
      img{
        &.site-map{
          max-width: 700px;
        }
      }
      .ill{
        .ill2{
          left: -500px;
          img{
            &.dwl{
              max-width: 150px;
            }
            &.cadre{
              margin-right: 100px;
            }
          }
        }
      }
    }
  }
}

@media(min-width:992px) and (max-width:1200px) {
  .atelier {
    .slide2 {
      background-position:45% 92%;
      .chapter {
        padding-top: 650px;
      }
      .ill img {
        top:0;
        margin-top:30px;
        max-width: 140%;
        left: -43%;
      }
    }
    .slide4 {
      .chapter {
        padding-top:400px;
        padding-bottom:100px;
      }
      .ill img {
        left: -50%;
      }
    }
  }
  .bnp {
    .slide3 {
      .chapter {
        padding-top:600px;
      }
      .ill img {
        //max-width:85%;
        max-width: 120%;
        left: -40%;
      }
    }
  }
  .dima {
    .slide2 {
      background-position:70% 40%;
      .chapter {
        padding-top: 600px;
        padding-bottom: 50px;
      }
      .ill img {
        //max-width:85%;
        max-width: 130%;
        left: -45%;
      }
    }
    .slide3 {
      .chapter {
        padding-top:10px;
      }
      .ill img {
      }
      .ill2 {
        top: 50%;
        right: 100%;
        img {
          max-width:90%;
        }
      }
    }
    .slide4 {
      margin-top:120px;
    }
  }
  .verdun {
    .slide4 {
      .ill img {
        max-width: 75%;
      }
    }
  }
  .avi {
    .slide2 {
      background-position:8% 70%;
      .chapter {
        padding-top: 70px;
        padding-bottom:250px;
      }
      .ill img {
        max-width:100%;
        left: -70px;
        padding-right: 45%;
      }
    }
    .slide3 {
      .chapter {
        padding-bottom:100px;
      }
      .ill img {
        max-width: 150%;
      }
    }
  }
  // PAGE CENTENAIRE
  .centenaire {
    .slide2 {
      .chapter {
        padding-bottom:400px;
      }
    }
    .slide3 {
      padding-bottom:500px;
      .chapter {
        margin-right:8%;
        padding-top:140px;
        position:static;
      }
      .ill2 {
        position:static;
        img {
          position:absolute;
          bottom:0;
          top:auto;
          left:50%;
          margin-left: -190px;
        }
      }
    }
    .slide4 {
      .chapter {
        margin-right:8%;
        padding-bottom:100px;
      }
      .ill img {
      }
    }
    .slide5 {
      .chapter {
        padding-top:0;
      }
    }
  }
  .lea {
    .slide2 {
      .ill1 img {
        max-width:115%;
      }
      .ill2 img {
        max-width:125%;
      }
      .chapter {
        padding-top:150px;
        padding-bottom:15%;
      }
    }
    .slide3 {
      background-position:30% 95%;
      .ill img {
        max-width:140%;
        right:-25%;
        top:50px;
      }
      .chapter {
        padding-top:5%;
        padding-bottom: 300px;
      }
    }
    .slide4 {
      .ill img {
        left: -150px;
        max-width: 65%;
      }
      .chapter {
        padding-bottom:150px;
      }
    }
  }

  /* bdf */
  .banque{
    .slide3{
      margin-top: 50px;
      .ill{
        img{
          top :-1150px;
          left: -450px;
          max-width: 300%;
        }
      }
      .chapter{
        padding-top: 600px;
        padding-bottom: 200px;
      }
    }
    .slide4{
      .chapter{
        margin-top: 0;
        padding-right: 0;
        padding-bottom: 200px;
      }
    }
    .ill2{
      display: none;
    }
  }
}

@media(max-width:1040px) {
  .hp {
    .cont-projets {
      width:100%;
      max-width:970px;
    }
    .projet-hp {
     width:440px;
    }
  }
  .bnp {
    .slide2 {
      .ill img {
      }
      .chapter {
      }
    }
  }
  .nav-bottom {
    width:950px;
  }
  footer.footer-contact {
     .contact-container {
      width:950px;
    }
  }
}

@media(max-width:1199px) {
  // MENU
  .nav {
    .menu-container {
      .menu-ouvert {
        width:100%;
        max-width:750px;
      }
      .menu-desktop {
        .projet-title a {
          font-size:30px;
          .smaller {
            display:none;
          }
          &:before {
            top: 50%;
            -moz-transform:translateY(-50%);
            -webkit-transform:translateY(-50%);
            -o-transform:translateY(-50%);
            -ms-transform:translateY(-50%);
            transform:translateY(-50%);
          }
        }
        .title-section {
          font-size:22px;
        }
      }
    }
  }
}

@media(max-width:991px) {
  .hp {
    .cont-projets {
      width:100%;
      max-width:750px;
    }
    .projet-hp {
     max-width:330px;
     width:100%;
     text-align:center;
     .button {
      margin:20px auto 0;
     }
    }
    h1 {
      .mot-left, .mot-right {
        width:42%;
        font-size:34px;
      }
    }
  }
  .leprojet {
    margin-left:-15px;
    margin-right:-15px;
    padding:40px 0;
    .details {
      margin-top:30px;
      .button {
        margin:auto;
      }
      &:nth-child(1) {
        margin-top:0;
      }
    }
  }
  .voirSite {
    margin-bottom:80px;
  }
  // PAGES PROJET : GLOBAL
  .projet {
    h1 {
    }
    // overflow-x: hidden;
    .slide2,.slide3, .slide4 {
      position: relative;
      margin:30px 0;
    }
    .chapter{
      float:none !important;
      max-width:750px !important;
      margin:auto;
      h2 br {
        display:none;
      }
      p {
        
      }
    }
    .ch-r {
      max-width:none;
    }
    .ch-l {
      max-width:none;
    }
    .ill img{
      position:relative;
    }
    .slide2 {
      .chapter {
        margin-top:0;
      }
    }
  }
  // PAGE ATELIER
  .atelier {
    .slide2 {
      background-position:50% bottom;
      .chapter {
        padding:0;
        margin-bottom:30px;
      }
      .ill img {
        top:0;
        padding:0;
        /*max-width: 115%;
        left:-3%;*/
        max-width: 160%;
        left: -50%;
      }
    }
    .slide3 {
      .chapter {
        padding:0;
        margin-bottom:30px;
      }
      .ill img {
        width:100%;
        max-width:750px;
        margin:auto;
        display:table;
      }
    }
    .slide4 {
      .chapter {
        padding:0;
        margin-bottom:30px;
      }
      .ill img {
        padding: 0;
        left: -65%;
        max-width: 165%;
      }
    }
  }
  // PAGE GROUPE
  .bnp {
    .slide2 {
      .chapter {
        padding:0;
      }
    }
    .slide3 {
      .chapter {
        padding:0;
        margin-bottom:30px;
      }
      .ill img {
        //max-width:120%;
        //margin-left:-15px;
        max-width: 180%;
        left:-65%;
      }
    }
    .slide4 {
      .chapter {
        padding:0;
        margin-bottom:30px;
      }
      .ill {
        padding:0;
        img {
          left:5px;
          width:100%;
          max-width:750px;
          margin:auto;
          display:table;
        }
      }
    }
  }
  // PAGE DIMA
  .dima {
    .slide2 {
      background-position:85% 90%;
      .chapter {
        padding:0;
      }
      .ill img {
        top:0;
        //left: -10%;
        //max-width: 125%;
        left: -65%;
        max-width: 180%;
      }
    }
    .slide3 {
      .chapter {
        padding:0;
        margin-bottom:30px;
      }
      .ill img {
        max-width: 114%;
        margin-left: -5%;
      }
      .ill2 img {
        display:none;
      }
    }
    .slide4 {
      .chapter {
        padding:0;
        margin:-60px auto 30px;
      }
      .ill {
        padding:0;
        img {
          width:100%;
          max-width:750px;
          margin:auto;
          display:table;
        }
      }
    }
  }
  // PAGE VERDUN
  .verdun {
    .slide2 {
      background-position:20% 100%;
      .chapter {
        padding:0;
        margin-bottom:30px;
      }
      .ill img {
        top:0;
        max-width: 130%;
        right:0%;
        left: auto;
      }
    }
    .slide3 {
      .chapter {
        padding:0;
      }
      .ill1 img {
        display:none
      }
      .ill2 img {
        left: -7%;
        top: 0;
        max-width: 115%;
      }
    }
    .slide4 {
      .chapter {
        padding:0;
        margin-bottom:30px;
      }
      .ill img {
        max-width: 110%;
        left: -5%;
      }
    }
  }
  // PAGE AVI
  .avi {
    .slide2 {
      background-position:12% 100%;
      .chapter {
        padding:0;
      }
      .ill {
        text-align:center;
        img {
          top:0;
          left:-10%;
          max-width:100%;
          margin: 30px auto 0;
        }
      }
    }
    .slide3 {
      .chapter {
        padding:0;
      }
      .ill img {
        max-width: 110%;
        left: -5%;
        padding:0;
        margin-top:30px;
        margin-bottom:30px;
      }
    }
  }
  // PAGE CENTENAIRE
  .centenaire {
    h1 {
    }
    .chapter{
    }
    .slide1{
    }
    .slide2 {
      background-position:0% 100%;
      background-size: 50%;
      .ill {
        img {
          top:0;
          max-width:130%;
          right: 0;
          left: auto;
        }
      }
      .chapter {
        padding:0;
      }
    }
    .slide3 {
      .chapter {
        padding:0;
        margin:0 auto;
      }
      .ill1 {
        img {
          left:0;
          right:0;
          display:table;
          margin:auto;
        }
      }
      .ill2 {
        img {
          left:-7%;
          right:0;
          display:table;
          margin:40px auto;
          max-width:100%;
        }
      }
    }
    .slide4 {
      .chapter {
        margin:0 auto;
        padding:0;
      }
      .ill img {
        left:0;
        right:0;
        display:table;
        margin:auto;
      }
    }
    .slide5 {
      .chapter {
        padding:0;
      }
      .ill img {
        left:0;
        right:0;
        display:table;
        margin:auto;
        float:none;
      }
    }
  }
  // PAGE LEA
  .lea {
    h1 {
    }
    .chapter{
    }
    .slide1{
    }
    .slide2 {
      .ills {
        position:relative;
      }
      .ill1 {
        position:absolute;
        margin-left: 25%;
        top: 12%;
        margin-top:0;
        img {
          max-width: 100%;
        }
      }
      .ill2 {
        position:relative;
        left:0;
        top:0;
        img {
          max-width: 80%;
          left: -50px;
        }
      }
      .chapter {
        padding:0;
      }
    }
    .slide3 {
      background-position: 50% 100%;
      background-size: 250px;
      .chapter {
        padding:0;
      }
      .ill {
        float:none;
        padding-bottom: 100px;
        img {
          position:relative;
          left:-5%;
          max-width:160%;
          top:0;
          right:auto;
          display:table;
          margin:50px auto 0;
        }
      }
    }
    .slide4 {
      .chapter {
        margin:0 auto;
        padding:0;
      }
      .ill img {
        position:relative;
        max-width: 110%;
        left: -5%;
        display:table;
        margin:auto;
      }
    }
  }

  // Page BDF

  .banque{
    .slide3{
      margin-top: 20px;
      margin-bottom: 0;
      .ill{
        img{
          position: relative;
          top: 0px;
          left: -30%;
          max-width: 150%
        }
      }
      .chapter{
        padding-top: 0;
        padding-bottom: 0;
      }
    }
    .slide4{
      margin-top: 20px;
      .chapter{
        margin-top: 0;
        padding-bottom: 0;
        padding-right: 0;
      }
      .ill1{
        text-align: center;
        img{
          position: relative;
          top: 0;
          max-width: 80%;
        }
      }
      .ill2{
        display: none;
      }
    }
  }


  .nav-bottom {
    a {
      width:100%;
    }
    a#next:after {
      bottom:auto;
      top:20px;
    }
  }
  .footer-nav {
    padding:0;
    .nav-bottom {
      width:100%;
    }
  }
  footer.footer-contact {
    .contact-container {
      width:100%;
      .contact-sub {
        display:block;
        padding:0 0 0 15px;
        &:first-child {
          padding:0;
        }
      }
    }
    p {
      display:inline-block;
      padding:5px 10px 5px 0;
    }
  }

  //PAGE FANTASY
  .fantasy{
    .slide2{
      padding-top: 50px;
      padding-bottom: 100px;
      .ill{
        margin-top: 80px;
        img{
          position: relative;
          top: 0;
          left: 0;
          max-width: 100%;
          width: 100%;
        }
        .ill2{
          width: 150px;
          top:-70px;
          left: 75%;
        }
      }
    }
    .slide3{
      display: flex;
      flex-direction: column;
      .chapter{
        order: 1;
        padding-top: 0;
        padding-bottom: 50px;
      }
      .ill{
        order: 2;
        margin: 0 auto;
        &:first-child{
          transform: translateX(-80px);
        }
      }
    }
    .slide5{
      .ill{
        margin-top: 35px;
        img{
          display: table;
          width: 85%;
          max-width: 750px;
          margin: 0 auto 0;
        }
      }
    }
    .slide6{
      padding-top: 50px;
      padding-bottom: 450px;
      .ill{
        
        img{
          position: absolute;
          left: -150px;
          top: -300px;
          width: 500px;
        }
      }
    }
    .slide7{
      img{
        &.site-map{
          max-width: 70%;
          top: -350px;
        }
      }
      .ill{
        padding-top: 100px;
        text-align: center;
        max-width: 750px;
        margin : 0 auto;
        img{
          &.long-img{
            display: block;
            margin: 0 auto;
            width: 100%;
            max-width: 100%;
          }
        }
        .ill2{
          position: absolute;
          top: 30%;
          left: -20px;
          z-index: 1;
          text-align: left;
          img{
            &.cadre{
              margin-right: 0;
              display: none;
            }
            &.dwl{
              margin-left: 0;
            }
          }
        }
      }
    }
  }


}


@media (min-width:768px) {
  .rwd-break {
    display: none;
  }
  .bloc-menu-mobile {
    display:none !important;
  }
}



@media (max-width: 767px) {
  .fullpage {
    &.fadeout {
      //transition:opacity 0.4s ease;
      opacity:0;
    }
  }
  // MENU
  nav {
    .logo {
      display:none !important;
    }
    .nav-wrapper {
      width:40px;
      top: 15px;
      left: 15px;
    }
    .menu-container .menu-wrapper {
      position:static;
      transform:none;
    }
    .menu-container {
      width:100vw;
      height:100vh;
      left:0;
      top:0;
      transform:translateX(-100%);
      .menu-ouvert {
        transform: none;
        left: 0;
        top: 0;
        bottom: 0;
        position:absolute;
      }
      &.opened {
        /*width:100%;*/
        transform:translateX(0%);
      }
      .menu-desktop {
        display:none;
      }
      .menu-mobile {
        display:block;
      }
    }
    &.open {
      .nav-wrapper {
        position:absolute;
        box-shadow:none;
        -webkit-box-shadow:none;
        right:15px;
        top:15px;
        left:auto;
      }
      .menu-ham {
        float:right;
      }
    }
    &.level2 {
      .menu-ham {
        border-color:#515151;
        .line {
          background-color:#515151 !important;
        }
      }
    }
  }
  .ch-r {
    // margin-right: 15%!important;
    margin-right:40px;
  }
  .ch-l {
    // margin-left: 10%!important;
    margin-left: 40px;
  }
  // HOMEPAGE
  .hp {
    .chapo {
      p:last-child {
        display:none;
      }
      br {
        display:none;
      }
    }
    .liste-projets {
      padding-top:0;
    }
    .projet-hp {
      display: table;
      margin: 50px auto !important;
      float:none;
    }
    h1 {
      .mot-left, .mot-right {
        width:100%;
        text-align:center;
        left:50%;
        right:auto;
      }
      .mot-left {
        transform:translateX(-50%) translateY(100px);
      }
      .mot-right {
        transform:translateX(-50%) translateY(-100px);
      }
    }
    .flecheScrolling img {
      left:0px;
    }
    &.supoffset .projets {
      padding-top:80px;
    }
  }

  .cont-reduit {
    width: 100%;
  }
  nav .menu-container .title-section,
  nav .menu-container .projet-title a {
    font-size: 2rem;
  }
  // PAGES PROJET
  .projet {
    h1 {
      font-size:70px;
    }
    h2 {
      font-size:50px;
      line-height:55px;
    }
  }
  .bnp {
    .slide3 {
      .chapter {
        margin-left: 0;
      }
    }
  }
  .verdun {
    .slide1 {
      background-image:url("../img/verdun_md_bk.jpg") no-repeat;
    }
  }

  //FANTASY

  .fantasy{
    .slide2{
      .ill{
        .ill2{
          width: 20%;
          top: -12%;
        }
      }
    }
    .slide3{
      .ill{
        img{
          max-width: 250px;
        }
        &:first-child{
          transform: translateX(0);
        }
        .ill2{
          img{
            &.flottante{
              top: -30%;
              max-width: 50%;
              right: auto;
              left: 45%;
            }
          }
        }
      }
    }
    .slide6{
      padding-bottom: 300px;
      .ill{
        img{
          display: none;
        }
      }
    }
    .slide7{
      img{
        &.site-map{
          max-width: 85%;
          top: -300px;
        }
      }
    }
  }


  // FOOTER
  .footer-nav{
    width: 100%!important;
    padding:0!important;
  }
  footer.footer-contact {
    .flex {
      h4 {
        padding:0;
        margin-bottom: 10px;
      }
    }
    .contact-container {
      .contact-sub {
        padding:0;
        text-align:center;
      }
    }
    h4 {
      font-weight:400;
      margin-bottom:10px;
    }
    p {
      font-size:15px;
      display:block;
    }
  }
}

@media(max-width:650px) {
  .projet {
    h1 {
      font-size:60px;
    }
    h2 {
      font-size:46px;
      line-height:51px;
    }
  }
// PAGE BDF

.banque{
  .slide3{
    .ill{
      img{
        left : -75%;
        max-width: 200%;
      }
    }
  }
}


}

@media (max-width: 550px){
  //FANTASY
  .fantasy{
    .slide6{
      padding-bottom: 200px;
    }
    .slide7{
      img{
        &.site-map{
          max-width: 320px;
          top: -180px;
        }
      }
    }
  }
}

@media(min-width:481px) {
  .projet {
    .chapter {
      .minimize {
        display:inline !important;
      }
      .more {
        display:none !important;
      }
      .ellipsis {
        display:none !important;
      }
    }
  }
}

@media (max-width: 480px) {
  button {
    margin: auto!important;
  }
  h1 {
    //font-size: 2.5rem;
    margin: 4rem 0 0;
  }
  .logo {
      display:none;
  }
  nav {
    top: 0;
    left: 0;
    .nav-wrapper {
      /*box-shadow: -30px 23px 84px 8px rgba(0,0,0,.35);
      -webkit-box-shadow:-30px 23px 84px 8px rgba(0,0,0,.35);*/
    }
  }
  .desc {
    .details {
      text-align: center;
      .button {
        margin-top:30px;
      }
    }
  }
  .projet {
    .chapter {
      width: auto!important;
      height: auto!important;
      text-align: center;
      align-items: flex-start;
      padding-left: 0;
      margin-left: 0!important;
      margin-top: 0!important;
      .minimize {
        display:none;
      }
      .more {
        display:block;
        margin-top: 10px;
        font-size: 20px;
        &:hover, &:focus {
          color:inherit;
        }
      }
      .ellipsis {
        display:inline;
      }
    }
    .section {
      height: auto!important;
    }
    .chapo {
      font-size: 1rem;
      margin: 1.8rem auto;
      width: 100%;
    }
    #verdun-design {
      margin-top: 0px;
    }
  }
  .atelier {
    .slide4 {
      .chapter {
        h2 br {
          display:block;
        }
      }
    }
  }
  .verdun {
    .projet .slide1 {
      background-image:url("../img/verdun_sm_bk.jpg");
    }
  }
  .show-mobile {
    display: block;
    margin: 20px auto;
  }
  .show-desktop {
    display: none;
  }
  .footer {
    padding: 0;
    width: 100%;
    .nav-bottom {
      flex-direction: column-reverse;
    }
  }
  .dima {
    .slide2 {
      .ill img {
      }
    }
  }
  .atelier {
    .slide2 {
      .ill img {
        margin-left: -20px;
      }
    }
  }
  .lea {
    .slide2 {
      .ills {
        text-align:center;
      }
      .ill1 {
        left: 30%;
        top: 12%;
        margin-left: 0;
      }
    }
  }

  // PAGE FANTASY
  .fantasy{
    .slide5{
      .ill{
        margin-top: 60px;
      }
    }
    .slide6{
      .ch-r{
        width: 100%;
        margin-right: 0;
      }
    }
  }

  footer.footer-contact {
    .contact-container {
      display:block;
      .contact-sub {
        margin-bottom:20px;
        width:100%;
      }
    }
  }


}
@media (max-width: 425px) {
  .container {
    overflow:hidden;
  }
  .voirSite {
    overflow:visible;
  }
  .hp {
    h1 {
      padding:0 15px;
      #logo {
        /*transform: translateX(-50%) scale(0.8);*/
        width:70px;
        margin-top:-10px;
      }
    }
    .logo-text {
      width:190px;
      padding: 10px 0 18px;
      margin-top:-25px;
    }
    .ct-scrolldown {
      bottom:0;
    }
    .chapo {
      font-size:16px;
      margin-top: 20px;
    }
    .flecheScrolling {
      margin:15px auto 0;
      width: 22px;
    }
    &.scrolled {
      .logo-text:before {
        width:48px;
      }
      .logo-text:after {
        margin-right:-33px;
        width:55px;
      }
    }
  }
  .motto {
    margin:10px auto 40px;
  }
  .projet {
    h1 {
      font-size:50px;
    }
    .slide1 { 
      .device {
        img {
          //max-width:none;
          max-width:100%;
        }
      }
    }
  }
  .atelier {
    .slide2 {
      //background-position:50% bottom;
      background-position: 50% 92%;
      background-size: 30%;
    }
    .slide3 {
      margin: -70px 0 30px;
    }
  }
  /*.atelier {
    .slide1 {
      .device img {
        max-width:100%;
      }
    }
  }
  .bnp {
    .slide1 {
      .device img {
        max-width:100%;
      }
    }
  }
  .dima {
    .slide1 {
      .device img {
        max-width:100%;
      }
    }
  }
  .verdun {
    .slide1 {
      .device img {
        max-width:100%;
      }
    }
  }*/
}
