// COLORS
// $greyText : #343434;
$vert : #93c021;
$darkgrey: #7d7d7d;

$dima : #bec4d1;
$atelier : #f8ca99;
$bnp : #85ccb1;
$avi : #cbacd3;
$verdun : #a6d2d6;
$centenaire : #b0bfc4;
$lea : #c0dcf2;
$banque : #0059ab;
$fantasy: #f8e2a9;
//DEGRADES
// degrade logo
//* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#d1cd01+0,67b330+100 */
@mixin logo-gradient{
  background: rgb(209,205,1); /* Old browsers */
  background: -moz-linear-gradient(45deg, rgba(209,205,1,1) 0%, rgba(103,179,48,1) 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(45deg, rgba(209,205,1,1) 0%,rgba(103,179,48,1) 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(45deg, rgba(209,205,1,1) 0%,rgba(103,179,48,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#d1cd01', endColorstr='#67b330',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
}
// $gotham: 'Gotham', sans-serif;
